export enum QueryKeys {
  Ping = 'pingData',
  User = 'userData',
  Users = 'usersData',
  OrganisationUsers = 'organisationUsersData',
  Organisation = 'organisationData',
  Organisations = 'organisationsData',
  Services = 'servicesData',
  Trainings = 'trainingsData',
  AccessCode = 'accessCodeData',
  HealthCalls = 'healthCallsData',
  ScreeningResult = 'screeningResultData',
  healthSummary = 'healthSummaryData',
  healthPlan = 'healthPlanData',
}
