import styled from 'styled-components'
import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { BodyXlMedium, Title3 } from '../../../components/styles/Typography'
import { Wave } from '../../../components/wave/Wave'
import { theme } from '../../../theme/theme'
import { isMobile } from '../../../utils/devices'
import { useWindowWidth } from '../../../hooks/useWindowWidth'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { useGetUserHealthPlanQuery } from '../../../api/ester-b2b-api/private/users/userQueries'
import { Accordion } from '../../../components/Accordion'
import { Modules } from '@estercare/ester-shared'
import { MarkdownToReact } from '../../../components/markdown/MardownToReact'
import { ErrorGuard } from '../../../components/ErrorGuard'

export const HealthPlan = () => {
  const { data: healthPlan, isLoading: isHealthPlanLoading, isError } = useGetUserHealthPlanQuery()

  const width = useWindowWidth()
  const isMobileDevice = isMobile(width)

  if (isHealthPlanLoading) return <LoadingSpinner color={theme.color.plum} size={60} />

  if (isError) {
    return <ErrorGuard />
  }

  return (
    <>
      {!isMobileDevice && (
        <StyledWaveWrapper>
          <Wave color={theme.color.plum} />
        </StyledWaveWrapper>
      )}
      <ComponentLayout backgroundColor={theme.color.plum} paddingTop={isMobileDevice ? theme.spacing.xxlarge : 0}>
        <Container>
          <InnerContainer>
            <StyledTitle3>Livsstilsrekommendationer</StyledTitle3>
            {healthPlan &&
              healthPlan.plan.map((plan, index) => (
                <PlanContainer key={index}>
                  <StyledBodyXlMedium>{plan.title}</StyledBodyXlMedium>
                  {plan.modules
                    .sort((a, b) => Object.values(Modules).indexOf(a.id) - Object.values(Modules).indexOf(b.id))
                    .map((module, moduleIndex) => (
                      <Accordion key={moduleIndex} title={module.id}>
                        <MarkdownToReact markdown={module.text} />
                      </Accordion>
                    ))}
                </PlanContainer>
              ))}
          </InnerContainer>
        </Container>
      </ComponentLayout>
    </>
  )
}

const Container = styled.div`
  min-height: 80vh;
  display: flex;
  justify-content: center;
`

const InnerContainer = styled.div`
  width: 640px;
  max-width: 640px;
`

const StyledWaveWrapper = styled.div`
  z-index: 0;
  margin-top: -${theme.spacing.xxlarge}px;
`

const StyledTitle3 = styled(Title3)`
  color: ${theme.color.white};
  margin-bottom: ${theme.spacing.mediumLarge}px;
`

const StyledBodyXlMedium = styled(BodyXlMedium)`
  color: ${theme.color.white};
  margin-bottom: ${theme.spacing.medium}px;
`

const PlanContainer = styled.div`
  margin-bottom: ${theme.spacing.xxlarge}px;
`
