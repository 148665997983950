/* eslint-disable @typescript-eslint/no-unused-vars */
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import {
  BodyLarge,
  BodyLargeMediumItalic,
  RichTextLink,
  Title1,
  Title2,
  Title3,
  Title4,
  Title5,
  Title6,
} from '../styles/Typography'

const TitleWrapper = styled.div`
  margin-bottom: ${theme.spacing.small}px;
  margin-top: ${theme.spacing.small}px;
`

const StyledH1 = styled(Title1)`
  margin-top: ${theme.spacing.tiny}px;
  margin-bottom: ${theme.spacing.small}px;
`

const StyledH2 = styled(Title2)`
  margin-top: ${theme.spacing.medium}px;
  margin-bottom: ${theme.spacing.medium}px;
`

const StyledEm = styled(BodyLargeMediumItalic)`
  margin-top: ${theme.spacing.medium}px;
  margin-bottom: ${theme.spacing.medium}px;
`

const StyledP = styled(BodyLarge)`
  margin-bottom: ${theme.spacing.medium}px;
`

export const MarkdownToReact = ({ markdown }: { markdown: string }) => {
  return (
    <ReactMarkdown
      components={{
        h1: ({ node, ref, ...props }) => <StyledH1 {...props} />,
        h2: ({ node, ref, ...props }) => <StyledH2 {...props} />,
        h3: ({ node, ref, ...props }) => (
          <TitleWrapper>
            <Title3 {...props} />
          </TitleWrapper>
        ),
        h4: ({ node, ref, ...props }) => (
          <TitleWrapper>
            <Title4 {...props} />
          </TitleWrapper>
        ),
        h5: ({ node, ref, ...props }) => (
          <TitleWrapper>
            <Title5 {...props} />
          </TitleWrapper>
        ),
        h6: ({ node, ref, ...props }) => (
          <TitleWrapper>
            <Title6 {...props} />
          </TitleWrapper>
        ),
        p: ({ node, ref, ...props }) => <StyledP {...props} />,

        li: ({ node, ref, ...props }) => <li {...props} />,
        ol: (props) => <ol {...props} />,

        a: (props) => <RichTextLink {...props} />,
        em: ({ node, ref, ...props }) => <StyledEm {...props} />,
        blockquote: (props) => <blockquote {...props} />,
        br: (props) => <br {...props} />,
      }}
    >
      {markdown}
    </ReactMarkdown>
  )
}
